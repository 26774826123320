import { Button } from "@/components/ui/button"
import { Eye, EyeOff } from "lucide-react"

interface IdsToggleButtonProps {
  handleToggle: () => void
  showIds: boolean
  label: string
}

const IdsToggleButton: React.FC<IdsToggleButtonProps> = ({ handleToggle, showIds, label }) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={handleToggle}
      className="flex items-center gap-2 text-white hover:text-white hover:bg-gray-700 border border-white rounded-sm">
      {showIds ? (
        <>
          <EyeOff className="h-4 w-4" /> Hide {label} IDs
        </>
      ) : (
        <>
          <Eye className="h-4 w-4" /> Show {label} IDs
        </>
      )}
    </Button>
  )
}

export default IdsToggleButton
