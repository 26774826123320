import { useInternalToolbarStore } from "./store"
import IdsToggleButton from "./IdsToggleButton"

const SignatureIdsToggleButton = () => {
  const { showSignatureId, setShowSignatureId } = useInternalToolbarStore()

  const handleToggle = () => {
    setShowSignatureId(!showSignatureId)
  }

  return <IdsToggleButton handleToggle={handleToggle} showIds={showSignatureId} label="Signature" />
}

export default SignatureIdsToggleButton
