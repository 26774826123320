import { useInternalToolbarStore } from "./store"
import IdsToggleButton from "./IdsToggleButton"

const ReplayIdsToggleButton = () => {
  const { showReplayIds, setShowReplayIds } = useInternalToolbarStore()

  const handleToggle = () => {
    setShowReplayIds(!showReplayIds)
  }

  return <IdsToggleButton handleToggle={handleToggle} showIds={showReplayIds} label="Replay" />
}

export default ReplayIdsToggleButton
