"use client"
import React from "react"

import { OrganizationCombobox } from "./organization-combobox"
import ReplayIdsToggleButton from "./replaysIdsToggleButton"
import SignatureIdsToggleButton from "./signatureIdsToggleButton"
import ErrorIdsToggleButton from "./errorIdsToggleButton"

const InternalToolbar = ({ orgs, viewingAsName }: { orgs: any[]; viewingAsName: string }) => {
  return (
    <div className="fixed bottom-5 left-5 bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg z-50 min-w-1/4 w-auto h-16 flex items-center justify-center gap-4">
      <ReplayIdsToggleButton />
      <SignatureIdsToggleButton />
      <ErrorIdsToggleButton />
      <OrganizationCombobox
        orgs={orgs}
        viewingAsName={viewingAsName}
        onOrgChange={(customerId) => {
          fetch("/api/mock", {
            method: "POST",
            body: JSON.stringify({ newCustomerId: customerId }),
          })
          window.location.reload()
        }}
      />
    </div>
  )
}

export default InternalToolbar
