"use client"
import { Button } from "@/components/ui/button"
import Link from "next/link"
import { CiViewList } from "react-icons/ci"
import {
  PiSirenThin,
  PiBuildingsThin,
  PiCreditCardThin,
  PiMonitorPlayThin,
  PiArrowSquareOutLight,
  PiBookOpenTextThin,
  PiSparkleLight,
  PiPathThin
} from "react-icons/pi"
import { HiOutlineDocumentReport } from "react-icons/hi"

import { VscBracketError } from "react-icons/vsc"
import { usePathname } from "next/navigation"
import { SidebarGroup, SidebarGroupContent, SidebarMenu, SidebarMenuItem, SidebarSeparator } from "../sidebar"
import { Activity } from "lucide-react"

export function SidebarNavItems({
  isDemo,
  orgId,
  errorsFeatureEnabled,
}: {
  isDemo: boolean
  orgId: number | null
  errorsFeatureEnabled: boolean
}) {
  const pathName = usePathname()

  function getButtonVariant(neededPath: string): "default" | "ghost" {
    if (neededPath === "/errors") {
      return pathName === neededPath || pathName.includes("/issue/") ? "default" : "ghost"
    } else {
      return pathName.includes(neededPath) ? "default" : "ghost"
    }
  }

  return (
    <div className="pt-2 ml-2">
      <SidebarGroup className="p-0">
        <SidebarGroupContent className="w-68">
          <SidebarMenu>
            <SidebarMenuItem>
              <Link href="/issues">
                <Button variant={getButtonVariant("/issues")} className="w-full justify-start">
                  <CiViewList size={25} className="mr-3" /> Issues
                </Button>
              </Link>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <Link href="/replays">
                <Button variant={getButtonVariant("/replays")} className="w-full justify-start">
                  <PiMonitorPlayThin size={25} className="mr-3" />
                  All Replays
                </Button>
              </Link>
            </SidebarMenuItem>
            {isDemo && (
              <SidebarMenuItem>
                <Link href="/behavior">
                  <Button variant={getButtonVariant("/behavior")} className="w-full justify-start">
                    <Activity size={25} className="mr-3" />
                    User Behavior
                  </Button>
                </Link>
              </SidebarMenuItem>
            )}
            {orgId && errorsFeatureEnabled && (
              <SidebarMenuItem>
                <Link href="/errors">
                  <Button variant={getButtonVariant("/errors")} className="w-full justify-start">
                    <VscBracketError size={25} className="mr-3" /> Errors
                  </Button>
                </Link>
              </SidebarMenuItem>
            )}
            {isDemo && (
              <SidebarMenuItem>
                <Link href="/reports">
                  <Button variant={getButtonVariant("/reports")} className="w-full justify-start">
                    <HiOutlineDocumentReport size={25} className="mr-3" />
                    Reports
                  </Button>
                </Link>
              </SidebarMenuItem>
            )}
            {isDemo && (
              <SidebarMenuItem>
                <Link href="/insights">
                  <Button variant={getButtonVariant("/insights")} className="w-full justify-start">
                    <PiSparkleLight size={25} className="mr-3" />
                    Insights
                  </Button>
                </Link>
              </SidebarMenuItem>
            )}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarSeparator className="mx-0 w-68 my-3" />
      <SidebarGroup className="p-0">
        <SidebarGroupContent className="w-68">
          <SidebarMenu>
            <SidebarMenuItem>
              <Link href="/alerts">
                <Button variant={getButtonVariant("/alerts")} className="w-full justify-start">
                  <PiSirenThin size={25} className="mr-3" /> Alerts
                </Button>
              </Link>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <Link href="/settings">
                <Button variant={getButtonVariant("/settings")} className="w-full justify-start">
                  <PiBuildingsThin size={25} className="mr-3" />
                  Organization Settings
                </Button>
              </Link>
            </SidebarMenuItem>

            {isDemo && (
              <SidebarMenuItem>
                <Button variant={getButtonVariant("/billing")} className="w-full justify-start">
                  <PiCreditCardThin size={25} className="mr-3" /> Billing
                </Button>
              </SidebarMenuItem>
            )}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarSeparator className="mx-0 w-68 my-3" />
      <SidebarGroup className="p-0">
        <SidebarGroupContent className="w-68">
          <SidebarMenu>
            <SidebarMenuItem>
              <Link href="https://docs.getdecipher.com" target="_blank">
                <Button variant={getButtonVariant("/docs")} className="w-full justify-start">
                  <PiBookOpenTextThin size={25} className="mr-3" /> Docs <PiArrowSquareOutLight size={16} className="ml-1" />
                </Button>
              </Link>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    </div>
  )
}
