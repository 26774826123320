import { useInternalToolbarStore } from "./store"
import IdsToggleButton from "./IdsToggleButton"

const ErrorIdsToggleButton = () => {
  const { showErrorIds, setShowErrorIds } = useInternalToolbarStore()

  const handleToggle = () => {
    setShowErrorIds(!showErrorIds)
  }

  return <IdsToggleButton handleToggle={handleToggle} showIds={showErrorIds} label="Error" />
}

export default ErrorIdsToggleButton
