"use client"

import { CiViewList } from "react-icons/ci"
import { PiSirenThin, PiBuildingsThin, PiCreditCardThin, PiMonitorPlayThin } from "react-icons/pi"
import { VscBracketError } from "react-icons/vsc"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { PiSparkleLight } from "react-icons/pi"
import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { useEffect, useState } from "react"
import { useRouter } from "next/navigation"

interface CommandMenuProps {
  isDemo: boolean
  orgId: number | undefined
  errorsFeatureEnabled: boolean
}

export function CommandMenu({ isDemo, orgId, errorsFeatureEnabled }: CommandMenuProps) {
  const [open, setOpen] = useState(false)
  const router = useRouter()

  const suggestions = [
    { icon: VscBracketError, label: "Errors", path: "/errors", showIf: orgId && errorsFeatureEnabled },
    { icon: CiViewList, label: "Issues", path: "/issues" },
    { icon: PiMonitorPlayThin, label: "All Replays", path: "/replays" },
    { icon: HiOutlineDocumentReport, label: "Reports", path: "/reports", showIf: isDemo },
    { icon: PiSparkleLight, label: "Insights", path: "/insights", showIf: isDemo },
  ]

  const settings = [
    { icon: PiSirenThin, label: "Alerts", path: "/alerts" },
    { icon: PiBuildingsThin, label: "Settings", path: "/settings" },
    // { icon: PiCreditCardThin, label: "Billing", path: "/billing" },
  ]

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }
    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  const runCommand = (command: () => void) => {
    setOpen(false)
    command()
  }

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <Command className="rounded-lg shadow-md">
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Suggestions">
            {suggestions
              .filter((item) => item.showIf === undefined || item.showIf)
              .map((item) => (
                <CommandItem key={item.path} onSelect={() => runCommand(() => router.push(item.path))}>
                  <item.icon className="mr-2 h-4 w-4" />
                  <span>{item.label}</span>
                </CommandItem>
              ))}
          </CommandGroup>
          <CommandGroup heading="Settings">
            {settings.map((item) => (
              <CommandItem key={item.path} onSelect={() => runCommand(() => router.push(item.path))}>
                <item.icon className="mr-2 h-4 w-4" />
                <span>{item.label}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </CommandDialog>
  )
}
